import { registerApplication, start, LifeCycles } from "single-spa";

const loginAppPaths = ["/login", "/forgot-password", "/onboarding"];
const recaudosAppPaths = ["/irispay"];
const complementosAppPaths = ["/complements"];

const loginActiveWhen = (location: Location) =>
  loginAppPaths.find((path) => location.pathname.startsWith(path)) !== undefined;

const navBarActiveWhen = (location: Location) => !loginActiveWhen(location);

const recaudosActiveWhen = (location: Location) =>
  navBarActiveWhen(location) &&
  recaudosAppPaths.find((path) => location.pathname.startsWith(path)) !== undefined;

const complementosActiveWhen = (location: Location) =>
  navBarActiveWhen(location) &&
  complementosAppPaths.find((path) => location.pathname.startsWith(path)) !== undefined;

const bancaDigitalActiveWhen = (location: Location) =>
  navBarActiveWhen(location) &&
  !recaudosActiveWhen(location) &&
  !complementosActiveWhen(location);

registerApplication({
  name: "@iris/login",
  app: async () => {
    const module = await System.import("@iris/login");
    return module as LifeCycles<{}>;
  },
  activeWhen: loginActiveWhen,
});

registerApplication({
  name: "@iris/navbar",
  app: async () => {
    const module = await System.import("@iris/navbar");
    return module as LifeCycles<{}>;
  },
  activeWhen: navBarActiveWhen,
});

registerApplication({
  name: "@iris/bancadigital",
  app: async () => {
    const module = await System.import("@iris/bancadigital");
    return module as LifeCycles<{}>;
  },
  activeWhen: bancaDigitalActiveWhen,
});

registerApplication({
  name: "@iris/irispay",
  app: async () => {
    const module = await System.import("@iris/irispay");
    return module as LifeCycles<{}>;
  },
  activeWhen: recaudosActiveWhen,
});

registerApplication({
  name: "@iris/complementos",
  app: async () => {
    const module = await System.import("@iris/complementos");
    return module as LifeCycles<{}>;
  },
  activeWhen: complementosActiveWhen,
});

start({
  urlRerouteOnly: true,
});
